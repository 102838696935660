import * as React from 'react';
import {Layout} from 'antd';
import { inject, Provider} from 'mobx-react';
import { TabStore } from '../../stores/TabStore';
import autoBind from 'auto-bind';
import { NavMenu } from '../Shared/NavMenu';

const { Sider } = Layout;

@inject("tabStore")
export class SideBar extends React.Component < {
  tabStore : TabStore
} > {
  
  constructor(props : any) {
    super(props);
    autoBind(this);
  }

  render() {
    const {tabStore} = this.props;
    
    return (
        <Sider width={"15vw"}>
          <Provider tabStore={tabStore}>
            <NavMenu tabStore={tabStore} />
          </Provider>
        </Sider>
    );
  }
}