import { Card, Tag } from 'antd';
import Meta from 'antd/lib/card/Meta';
import * as React from 'react';
import Fade from 'react-reveal/Fade';
import styles from './InterestSection.module.scss'
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { observer, Provider } from 'mobx-react';
import { ImageLibrary, ImageNames } from '../../Shared/ImageLibrary';
import { SectionHeader } from '../SectionHeader';
import resumeJSON from '../../../data/resume.json';
import { ComponentLoaderStore } from '../../../stores/ComponentLoaderStore';
import { CommentSection } from '../../Shared/CommentSection';
import { CommentSectionStore } from '../../../stores/CommentSectionStore';

@observer
export class InterestSection extends React.Component {
    animKey = Math.random();   
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    loaderStore: ComponentLoaderStore = new ComponentLoaderStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();

    componentDidMount(){
        this.loaderStore.setNumberOfResourcesToLoad(5);
    }

    render() {
        this.animKey = Math.random();
        return (
            <div id="interests">
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="List of some of my personal interests and hobbies." store={this.commentSectionStore}/>
                </Provider>
                <div className={this.loadComponent() ? styles.hidden : undefined}>
                    <SectionHeader>Interests</SectionHeader>
                </div>
                <div className={styles.interestSection} onLoad={() => this.codeLineNumberStore.updateSectionHeight(document.getElementById("interests")?.clientHeight as number)}>
                    {resumeJSON.interests.map((interest, i) => {     
                        return(
                            <Fade spy={this.animKey}  duration={500*(i+1)} top>
                                <Card
                                    hoverable
                                    className={this.loadComponent() ? styles.hidden : this.getInterestStyle(interest.name)}
                                    cover={<ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.interestGIF} image={this.getInterestGIF(interest.name) as ImageNames}/>}
                                >
                                    <Meta title={<div className={styles.interestTitle}>{interest.name}</div>} 
                                        description = {
                                            <>
                                                {interest.keywords.map((tag) => { 
                                                    return(
                                                        <Tag className={styles.interestTags} color="#0000000f">{`#${tag}`}</Tag>                                                )
                                                })}
                                            </>
                                        } 
                                    />
                                </Card>
                            </Fade>
                        );
                    })}
                </div>
            </div>
        )
    }

    private getInterestGIF(interest: string) : string{
        switch(interest) {
            case "Binge-watching":
                return "netflixGIF";
            case "Foodie":
                return "foodGIF";
            case "Travelling":
                return "travelGIF";
            case "RealMadrid":
                return "realmadridGIF"
            case "Gaming":
                return "gamingGIF"
            default:
                return "";
        }
    }

    private getInterestStyle(interest: string) {
        switch(interest) {
            case "Binge-watching":
                return styles.bingeWatching;
            case "Foodie":
                return styles.foodie;
            case "Travelling":
                return styles.travelling;
            case "RealMadrid":
                return styles.realmadrid;
            case "Gaming":
                return styles.gaming;
        }
    }

    private loadComponent () {
        if (this.loaderStore.areResourcesLoaded && this.commentSectionStore.areCommentsDisplayed) {
            this.loaderStore.componentLoaded = true;
            return false;
        }
        return true;
    }
}