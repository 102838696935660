import React from 'react';
import  styles from './Header.module.scss';
import {Layout, Typography} from 'antd';
import { ProfileCard } from './ProfileCard';
import { IconLibrary } from '../Shared/IconLibrary';
import { isMobile } from "react-device-detect";
import navDrawerStore from '../../stores/NavDrawerStore';

const {Text} = Typography;

export class Header extends React.Component {
    public render() {
        return ( 
        <> 
            <Layout.Header className={styles.header}>
                <div className={styles.alignLeft}>
                    {isMobile ? (
                        <div onClick={() => navDrawerStore.updateDrawerVisibility()}>
                            <IconLibrary icon="bars" color="white"/>&nbsp;
                        </div>
                    ) : (
                        <>
                            <IconLibrary icon="circle" color="#fd5652"/>&nbsp;
                            <IconLibrary icon="circle" color="#fdbd41"/>&nbsp;
                            <IconLibrary icon="circle" color="#33c949"/>&nbsp;
                        </>
                    )}
                </div>
                <Text className={styles.headerTitle}>My Portofio</Text>
                <div className={styles.alignRight}> {<ProfileCard/>}</div>
            </Layout.Header>  
        </>
        )
    }
}