import { makeObservable, observable, action, computed } from 'mobx';
class SideBarStore {

    @observable public sideBarMenuCollapseStatus: boolean = false;

    constructor() { 
        makeObservable(this);
    }

    @action
    public updateSideBarMenuCollapseStatus = () => {
        this.sideBarMenuCollapseStatus = !this.sideBarMenuCollapseStatus;
    }

    @computed
    public get getSideBarMenuCollapseStatus() {
        return this.sideBarMenuCollapseStatus;
    }
}
const sideBarStore = new SideBarStore()

export default sideBarStore