import * as React from 'react';
import {Typography, notification} from 'antd';
import Typist from 'react-typist';
import styles from './WelcomeSection.module.scss'
import emoji from 'node-emoji'
import { ImageLibrary } from '../Shared/ImageLibrary';
import Fade from 'react-reveal/Fade';
import notifcationStore from '../../stores/NotificationStore';
import resumeJSON from '../../data/resume.json';
import { observer } from 'mobx-react';
import { isMobile } from "react-device-detect";
import welcomeSectionStore from '../../stores/WelcomeSectionStore';

const {Text} = Typography;

@observer
export class WelcomeSection extends React.Component {
    
    componentDidMount()  {
        if (!notifcationStore.getNotificationStatus) {
            setTimeout(function() {
                notification.open({
                    message: emoji.emojify('Hello !!! :wave:'),
                    description: emoji.emojify('Thanks for visiting my profile !!! :grin:'),
                    duration: 5
                });
            }, 50000);
            notifcationStore.updateNotificationStatus();
        }
    }

    public render() {
        return(
            <div className={styles.welcomeSection}>
                <div className={styles.typistWrapper}>
                    <Typist
                        className={styles.typist}
                        onTypingDone={() => isMobile ? welcomeSectionStore.updateProfileBannerVisibility() : null}
                        cursor={{
                            show: true,
                            blink: true,
                            element: '|',
                            hideWhenDone: false,
                            hideWhenDoneDelay: 1000
                        }}>
                        <Text className={styles.typistHello}>Hello, World!</Text>
                        <br/>
                        <Typist.Delay ms={1000} />
                        <Text className={styles.typistName}>{`I'm ${resumeJSON.basics.name}.`}</Text>
                        <br/>
                        <Typist.Delay ms={1000} />
                        <Text className={styles.typistProfession} >{`I'm a ${resumeJSON.basics.label}.`}</Text>
                    </Typist>
                </div>
                <div hidden={isMobile && !welcomeSectionStore.getProfileBannerVisibilityStatus } className={styles.profileBannerWrapper}>
                    <div className={styles.polaroidWrapper}>
                        <div className={styles.item}>
                            <div className={styles.polaroid}>
                                <Fade duration={3000}>
                                    <ImageLibrary className={styles.connectdp} image="nikitWelcomeImage"/>
                                </Fade>
                                <div className={styles.polaroidCaption}>{emoji.emojify("That's me :the_horns:")}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{clear: "both"}}></div>
            </div> 
        )
    }
}