import { inject, observer } from 'mobx-react';
import * as React from 'react';
import Typist from 'react-typist';
import { CommentSectionStore } from '../../stores/CommentSectionStore';
import styles from './CommentSection.module.scss'

interface CommentSectionProps {
    comment?: string;
    store : CommentSectionStore
}

@inject("store")
@observer
export class CommentSection extends React.Component<CommentSectionProps>  {
    
    public render() {
        
        return (
            <div className={styles.commentSection}>
                <Typist
                    onTypingDone={() => this.props.store.updateCommentDisplayStatus()}
                    avgTypingDelay={50}
                    cursor={{
                        show: true,
                        blink: true,
                        element: '|',
                        hideWhenDone: true
                    }}
                >
                    <span className={styles.comment}>{`/// <summary>`}</span><br/>
                    <span className={styles.comment}>{`///`}&nbsp;&nbsp;{this.props.comment}</span><br/>  
                    <span className={styles.comment}>{`/// </summary>`}</span>
                </Typist>
            </div>
        );
    }
}