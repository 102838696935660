import * as React from 'react';
import { Drawer } from 'antd';
import { inject, observer, Provider} from 'mobx-react';
import { TabStore } from '../../stores/TabStore';
import autoBind from 'auto-bind';
import { NavMenu } from '../Shared/NavMenu';
import navDrawerStore from '../../stores/NavDrawerStore';
import  styles from './NavDrawer.module.scss';
import Text from 'antd/lib/typography/Text';
import { IconLibrary } from '../Shared/IconLibrary';

@inject("tabStore")
@observer
export class NavDrawer extends React.Component < {
  tabStore : TabStore
} > {
  
  constructor(props : any) {
    super(props);
    autoBind(this);
  }

  render() {
    const {tabStore} = this.props;
    const heartIcon: JSX.Element = <IconLibrary icon="heart" color="red" tooltip={<a href="https://reactjs.org/" target="blank">React</a>}/>;
    const coffeeIcon: JSX.Element = <IconLibrary icon="coffee" color="brown" tooltip={<a href="https://ant.design/" target="blank">Ant Design</a>}/>;
    return (
      <Drawer
          className={styles.navDrawer}
          placement="left"
          closable={false}
          onClose={() => navDrawerStore.updateDrawerVisibility()}
          visible={navDrawerStore.getNavDrawerVisibilityStatus}
          getContainer={false}
        >
          <Provider tabStore={tabStore}>
            <NavMenu tabStore={tabStore} />
          </Provider>
          <div className = {styles.navDrawerFooter}>
            <Text className = {styles.navDrawerFooterText}>Made with {heartIcon} and {coffeeIcon} in India</Text><br/>
            <Text className = {styles.navDrawerFooterText}>Copyright © Nikit Prabhu 2021</Text>
          </div>
        </Drawer>
    );
  }
}