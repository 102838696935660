import * as React from 'react';
import {Layout} from 'antd';
import styles from './ResumeSections.module.scss';
import { WelcomeSection } from './WelcomeSection';
import { observer, inject, Provider } from 'mobx-react';
import { SectionTabs } from './SectionTabs';
import { TabStore } from '../../stores/TabStore';
import autoBind from 'auto-bind';

@inject("tabStore")
@observer
export class ResumeSections extends React.Component < {
  tabStore : TabStore
} > {
  
    constructor(props : any) {
      super(props);
      autoBind(this);
    }

    public render(){
      const {tabStore} = this.props;
        return(
            <Layout.Content className={styles.resumeSection}>
                {!tabStore.isDefaultState ? (
                  <Provider tabStore={tabStore}>
                    <SectionTabs tabStore={tabStore} />
                  </Provider>
                ) : (
                  <WelcomeSection/>
                )}
            </Layout.Content>
        )    
    }
}