import Text from 'antd/lib/typography/Text';
import * as React from 'react';
import  styles from './SectionHeader.module.scss';

export class SectionHeader extends React.Component {

    render() {
        return (
            <Text className={styles.sectionHeader}>{this.props.children}</Text>
        );
    }
}