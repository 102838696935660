import { makeObservable, observable, action, computed } from 'mobx';
class WelcomeSectionStore {

    @observable public showProfileBanner: boolean = false;

    constructor() { 
        makeObservable(this);
    }

    @action
    public updateProfileBannerVisibility = () => {
        this.showProfileBanner = true;
    }

    @computed
    public get getProfileBannerVisibilityStatus() {
        return this.showProfileBanner;
    }
}
const welcomeSectionStore = new WelcomeSectionStore();
export default welcomeSectionStore;