import * as React from 'react';
import  styles from './ProfileCard.module.scss';
import {Avatar, Typography, Tooltip, Button} from 'antd';
import userPng from '../../../src/resources/nikit.jpg';
import { IconLibrary } from '../Shared/IconLibrary';
import { ImageLibrary } from '../Shared/ImageLibrary';
import resumeJSON from '../../data/resume.json';

const {Text} = Typography;

export class ProfileCard extends React.Component {
    public render(){
        return (
            <div>
                <Tooltip
                    placement="rightTop"
                    color='white'
                    title={
                        <div className={styles.card}>
                            <Avatar className={styles.profileAvatar} size={80} src={userPng}/>
                            <Text className={styles.profileName}>{resumeJSON.basics.name}</Text>
                            <Text className={styles.profileTitle}>{resumeJSON.basics.label}</Text>
                            <Text className={styles.profileLocation}>{`${resumeJSON.basics.location.city},${resumeJSON.basics.location.country}`}<ImageLibrary className={styles.countryFlag} image="indiaFlagPng"/></Text>
                            <Text className={styles.profileQoute}>"{resumeJSON.basics.favouriteQoute.quote}"<br/>{`- ${resumeJSON.basics.favouriteQoute.quotee}`}</Text>
                            <Button  className={styles.profileButton} href={this.getLinkedInURL()} target="blank" block> Let's Connect on &nbsp;<IconLibrary color="#0072b1" icon={['fab', 'linkedin']}/></Button>
                        </div>
                    }>
                    <Avatar size={30} src={userPng}/>
                </Tooltip>
            </div>
        )
    }

    private getLinkedInURL() {
        return resumeJSON.basics.profiles.filter((profile) => profile.network === "LinkedIn")[0].url;
    }
}