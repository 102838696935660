import * as React from 'react';
import  styles from './Footer.module.scss';
import {Layout, Typography} from 'antd';
import { IconLibrary } from '../Shared/IconLibrary';
import { isMobile } from "react-device-detect";

const {Text} = Typography;

export class Footer extends React.Component {
    public render(){
        const heartIcon: JSX.Element = <IconLibrary icon="heart" color="white" tooltip={<a href="https://reactjs.org/" target="blank">React</a>}/>;
        const coffeeIcon: JSX.Element = <IconLibrary icon="coffee" color="white" tooltip={<a href="https://ant.design/" target="blank">Ant Design</a>}/>;
        const syncIcon: JSX.Element = <IconLibrary icon="sync" color="white" />;
        const codeBranchIcon: JSX.Element = <IconLibrary icon="code-branch" color="white"/>;
        return(
            <Layout.Footer className = {styles.footer}>
                <Text className = {isMobile ? styles.alignCenter : styles.alignLeft}>{codeBranchIcon} users/nikit-prabhu/portfolio &nbsp; <div className = {styles.rotating}>{syncIcon}</div></Text>
                {isMobile ? (
                    null
                ) : (
                    <>
                        <Text className = {styles.alignCenter}>Copyright © Nikit Prabhu 2021</Text>
                        <Text className = {styles.alignRight}>Made with {heartIcon} and {coffeeIcon} in India</Text>
                    </>
                )}
            </Layout.Footer>
        )
    }
}