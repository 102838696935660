import { makeObservable, observable, action, computed } from 'mobx';

export class CodeLineNumberStore {

    @observable public sectionHeight: number = 0;

    constructor() { 
        makeObservable(this);
    }

    @action
    public updateSectionHeight = (height: number) => {
        this.sectionHeight = height;
    }

    @computed
    public get isSectionRendered() {
        return this.sectionHeight !== 0;
    }
}