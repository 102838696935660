import * as React from 'react';
import {Tabs} from 'antd';
import { inject, observer } from 'mobx-react';
import { TabStore } from '../../stores/TabStore';
import autoBind from 'auto-bind';
import  styles from './SectionTabs.module.scss';
import { TabData } from '../Shared/TabData';

const { TabPane } = Tabs;

@inject("tabStore")
@observer
export class SectionTabs extends React.Component< {
    tabStore : TabStore
  } > {
    
    constructor(props : any) {
        super(props);
        autoBind(this);
    }

    public render() {
        const {tabStore} = this.props;
        return (
            <div className={styles.sectiontabs}>
                <Tabs
                    className={styles.sectiontabs}
                    hideAdd
                    type="editable-card"
                    onChange={(tab) => tabStore.changeTab(tab)}
                    activeKey= {tabStore.activeTab}
                    onEdit={(tab) => tabStore.removeTab(tab.toString()) }
                    >
                    {tabStore.tabs.map(tabName => (
                        <TabPane tab={`${tabName}.me`} key={tabName}>
                            {TabData.getTabData(tabName)}
                        </TabPane>
                    ))}
                </Tabs>
            </div>
        )
    }
}