import { Tag, Timeline, Tooltip } from 'antd';
import Text from 'antd/lib/typography/Text';
import * as React from 'react';
import { IconLibrary } from '../../Shared/IconLibrary';
import  styles from './ExperienceSection.module.scss';
import Fade from 'react-reveal/Fade';
import Bounce from 'react-reveal/Bounce';
import { observer, Provider } from 'mobx-react';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import { ImageLibrary } from '../../Shared/ImageLibrary';
import { SectionHeader } from '../SectionHeader';
import resumeJSON from '../../../data/resume.json';
import { ComponentLoaderStore } from '../../../stores/ComponentLoaderStore';
import { CommentSection } from '../../Shared/CommentSection';
import { CommentSectionStore } from '../../../stores/CommentSectionStore';

const Themes  = ["Cyan","Red","Yellow"]

@observer
export class ExperienceSection extends React.Component {
    animKey = Math.random();
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    loaderStore: ComponentLoaderStore = new ComponentLoaderStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();
    componentDidMount() {
        this.loaderStore.setNumberOfResourcesToLoad(7);
        this.codeLineNumberStore.updateSectionHeight(document.getElementById("experience")?.clientHeight as number);
    }
    
    render() {
        this.animKey = Math.random();
        return(
            <div id="experience">
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="Highlighting my work experience till date." store={this.commentSectionStore}/>
                </Provider>
                <div className={this.loadComponent() ? styles.hidden : undefined}>
                    <SectionHeader>Experience</SectionHeader>
                </div>
                <div className={this.loadComponent() ? styles.hidden : styles.experienceSection}>
                    <Timeline>
                        {
                            resumeJSON.work.map((workExp, i) => {
                                return (
                                    <Timeline.Item dot={<IconLibrary color="#D2691E" icon="briefcase"/>}>
                                        <Fade spy={this.animKey} duration={500*(i+1)} right>
                                            <div className={this.getWorkExpTheme(Themes[i])}>
                                                <Tag color="#0000000f"><Text className={styles.jobPeriod}>{`${workExp.startDate}-${workExp.endDate}`}</Text></Tag><br/>
                                                <Text className={styles.jobTitle}>{workExp.position}</Text><br/>
                                                <Text className={styles.companyName}>{this.getCompanyIcon(workExp.company)}&nbsp;{workExp.company}</Text><br/>
                                                <br/>
                                                <Text className={styles.workDescription}>
                                                    {
                                                        workExp.summary.split('\n').map(function(item, key) 
                                                            {
                                                                return (
                                                                    <span key={key}>
                                                                    {item}
                                                                    <br/>
                                                                    </span>
                                                                )
                                                            }
                                                        )
                                                    }
                                                </Text>
                                                <div className={styles.techStackSection}>
                                                {
                                                    workExp.highlights.map((tech, i) => {
                                                        return (
                                                            <Bounce duration={500*(i+1)} bottom>
                                                                <Tooltip title={tech}><span>{this.getTeckStackIcons(tech)}&nbsp;&nbsp;</span></Tooltip>
                                                            </Bounce>
                                                        )
                                                    })
                                                }
                                                </div>
                                            </div>
                                        </Fade>
                                    </Timeline.Item>
                                );
                            })
                        }
                        <Timeline.Item dot={this.getCompanyIcon("Microsoft", "white")}>
                            <div className={styles.workExpStart} onLoad={() => this.codeLineNumberStore.updateSectionHeight(document.getElementById("experience")?.clientHeight as number)}>
                                <Text className={styles.workDescription}>Joined Microsoft as a college Hire on 15th June 2015.</Text>
                            </div>
                        </Timeline.Item>
                    </Timeline>
                </div>
            </div>
        )
    }

    private getWorkExpTheme(theme: string) {
        switch(theme) {
            case "Red":
                return styles.workExpCardThemeRed;
            case "Blue":
                return styles.workExpCardThemeBlue;
            case "Purple":
                return styles.workExpCardThemePurple;
            case "Cyan":
                return styles.workExpCardThemeCyan;
            default:
                return styles.workExpCardThemeYellow;
        }
    }

    private getCompanyIcon(companyName: string, color: string = "#0000004f") {
        switch(companyName) {
            case "Microsoft":
                return <IconLibrary color={color} icon={['fab', 'microsoft']}/>;
        }
    }

    private getTeckStackIcons(techName: string) {
        switch(techName) {
            case "Android":
                return <IconLibrary color={"#0000004f"} icon={['fab', 'android']}/>;
            case "Java":
                return <IconLibrary color={"#0000004f"} icon={['fab', 'java']}/>;
            case "React JS":
                return <IconLibrary color={"#0000004f"} icon={['fab', 'react']}/>;
            case "Node JS":
                return <IconLibrary color={"#0000004f"} icon={['fab', 'node-js']}/>;
            case "Docker":
                return <IconLibrary color={"#0000004f"} icon={['fab', 'docker']}/>;
            case "Xamarin":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="xamarinPNG"/>;
            case "Typescript":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="typescriptPNG"/>;
            case "Azure":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="azurePNG"/>;
            case "Logic Apps":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="logicappPNG"/>;
            case "Azure Functions":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="azurefunctionPNG"/>;
            case "Cosmos DB":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="cosmosPNG"/>
            case "Azure Data Factory":
                return <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.techStackIcon} image="azuredatafactoryPNG"/>
        }
    }

    private loadComponent () {
        if (this.loaderStore.areResourcesLoaded && this.commentSectionStore.areCommentsDisplayed) {
            this.loaderStore.componentLoaded = true;
            return false;
        }
        return true;
    }
}