import { ConnectSection } from "../ResumeSections/Connect/ConnectSection";
import { EducationSection } from "../ResumeSections/Education/EducationSection";
import { ExperienceSection } from "../ResumeSections/Experience/ExperienceSection";
import { InterestSection } from "../ResumeSections/Interest/InterestSection";
import { ProjectSection } from "../ResumeSections/Projects/ProjectSection";
import { SkillsSection } from "../ResumeSections/Skills/SkillsSection";

export abstract class TabData {
    
    private static tabsData: {[id: string] : JSX.Element;} = {
        "Education": <EducationSection/>,
        "Experience": <ExperienceSection/>,
        "Skills": <SkillsSection/>,
        "Projects": <ProjectSection/>,
        "Interests": <InterestSection/>,
        "Connect": <ConnectSection/>,
    };

    public static getTabData(tabName: string): JSX.Element {
        return this.tabsData[tabName];
    }
}