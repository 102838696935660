import { makeObservable, observable, action, computed } from 'mobx';
class NavDrawerStore {

    @observable public isDrawerVisible: boolean = false;

    constructor() { 
        makeObservable(this);
    }

    @action
    public updateDrawerVisibility = () => {
        this.isDrawerVisible = !this.isDrawerVisible;
    }

    @computed
    public get getNavDrawerVisibilityStatus() {
        return this.isDrawerVisible;
    }
}
const navDrawerStore = new NavDrawerStore()

export default navDrawerStore