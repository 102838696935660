import { Rate } from 'antd';
import Text from 'antd/lib/typography/Text';
import { observer, Provider } from 'mobx-react';
import * as React from 'react';
import { TagCloud } from 'react-tagcloud'
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { SectionHeader } from '../SectionHeader';
import  styles from './SkillsSection.module.scss';
import resumeJSON from '../../../data/resume.json';
import skillsJSON from '../../../data/skills.json';
import { CommentSection } from '../../Shared/CommentSection';
import {CommentSectionStore} from '../../../stores/CommentSectionStore';

enum skillLevel {
    Novice = 1,
    Intermediate = 2,
    Expert = 3
}
let height = 0;
@observer
export class SkillsSection extends React.Component {
    
    private getSkillTags() {
        var data: any = [];
        resumeJSON.skills.forEach((skill) => {
            skill.keywords.forEach((tag) => {
                data.push(
                    {
                        value:tag,
                        link: skillsJSON.skills.filter((skillUrl) => skillUrl.name === tag)[0].url,
                        count: skillLevel[skill.level as keyof typeof skillLevel]
                    }
                )
            });
        });
        return data;
    }

    private renderTags(tag: any) {
        if(tag.value === this.getSkillTags()[this.getSkillTags().length - 1].value) {
            this.codeLineNumberStore.updateSectionHeight(this.getHeight());
        }
        var tagstyle = undefined;
        switch(tag.count) {
            case 1:
                tagstyle = styles.noviceSkills;
                break;
            case 2:
                tagstyle = styles.intermediateSkills;
                break;
            case 3:
                tagstyle = styles.expertSkills;
                break;
        }
        return <a className={tagstyle} id={tag.value} href={tag.link} target="_blank" rel="noreferrer"><span>{tag.value}</span></a>;
    }
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();

    render() {
        return (
            <div id="skills" onLoad={() => this.codeLineNumberStore.updateSectionHeight(this.getHeight())}>
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="Technical skills with varying levels of proficiency." store={this.commentSectionStore}/>
                </Provider>
                <div className={this.loadComponent() ? styles.skillsSection : styles.hidden} onLoad={() => this.codeLineNumberStore.updateSectionHeight(this.getHeight())}>
                    <SectionHeader>Skills</SectionHeader>
                    <TagCloud
                        minSize={50}
                        maxSize={50}
                        tags={this.getSkillTags()}
                        shuffle={true}
                        renderer={(tags: any) => this.renderTags(tags)}
                    />
                    <div className={styles.skillsLegendSection}>
                        <Text className={styles.skillsLegendTitle}>Level of Proficiency</Text><br/>
                        <Rate className={styles.noviceStar} disabled value={1} />
                        <span className="ant-rate-text">Novice</span><br/>
                        <Rate className={styles.intermediateStar}  disabled value={3} />
                        <span className="ant-rate-text">Intermediate</span><br/>
                        <Rate className={styles.expertStar}  disabled value={5} />
                        <span className="ant-rate-text">Expert</span><br/>
                    </div>
                </div>
            </div>
        );
    }
    
    private loadComponent () {
        if (this.commentSectionStore.areCommentsDisplayed) {
            return true;
        }
        return false;
    }

    private getHeight() {
        if (document.getElementById("skills")?.clientHeight as number !== 0) {
            height = document.getElementById("skills")?.clientHeight as number;
        }
        return height;
    }
}