import * as React from 'react';
import Text from 'antd/lib/typography/Text';
import styles from './ProjectSection.module.scss'
import { observer, Provider } from 'mobx-react';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import Fade from 'react-reveal/Fade';
import { ImageLibrary, ImageNames } from '../../Shared/ImageLibrary';
import { SectionHeader } from '../SectionHeader';
import resumeJSON from '../../../data/resume.json';
import { isMobile } from "react-device-detect";
import { ComponentLoaderStore } from '../../../stores/ComponentLoaderStore';
import { CommentSection } from '../../Shared/CommentSection';
import { CommentSectionStore } from '../../../stores/CommentSectionStore';

const Themes = ["Purple", "Red", "Cyan"]

@observer
export class ProjectSection extends React.Component {
    animKey = Math.random();
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    loaderStore: ComponentLoaderStore = new ComponentLoaderStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();
    componentDidMount() {
        this.loaderStore.setNumberOfResourcesToLoad(3);
    }
    
    render() {
        this.animKey = Math.random();
        return (
            <div id="projects">
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="Some of the projects I have worked on." store={this.commentSectionStore}/>
                </Provider>
                <div className={this.loadComponent() ? styles.hidden : styles.projectSection} onLoad={() => this.codeLineNumberStore.updateSectionHeight(document.getElementById("projects")?.clientHeight as number)}>
                    <div>
                        <SectionHeader>Projects</SectionHeader><br/>
                    </div>
                    {
                        resumeJSON.projects.map((project,i) => {
                            if (isMobile) {
                                return this.renderProjectsOnMobile(project, i);
                            } else {
                                return this.renderProjects(project, i);
                            }
                        }) 
                    }
                    <div>
                        <Text className={styles.moreProjectsPlaceholder}>More Project Coming soon...</Text><br/>
                    </div>
                </div>
            </div>
        )
    }

    private getProjectTheme(theme: string) {
        switch(theme) {
            case "Red":
                return styles.projectThemeRed;
            case "Blue":
                return styles.projectThemeBlue;
            case "Purple":
                return styles.projectThemePurple;
            case "Cyan":
                return styles.projectThemeCyan;
            default:
                return styles.projectThemeYellow;
        }
    }

    private getProjectImage(project: string) {
        switch(project) {
            case "Coding Calendar":
                return "codingcalendarPNG";
            case "US Campaign electoral funding prediction 2018":
                return "uselectoralGIF";
            case "PexTrip":
                return "pextripJPG";
            default:
                return styles.projectThemeYellow;
        }
    }

    private renderProjects(project: any, index: number){
        return(
            <Fade duration={(index+1)*1000} spy={this.animKey} right>
                <div className={this.getProjectTheme(Themes[index])}>
                    <div className={styles.projectWrapper}>
                        <div className={styles.projectTextContent}>
                            <Text className={styles.projectName}>{project.name}</Text><br/>
                            <Text className={styles.projectDescription}>{project.summary}</Text>
                        </div>
                        <div className={styles.projectImageContent}>
                            <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.projectImage} image={this.getProjectImage(project.name) as ImageNames}/><br/>
                        </div>
                    </div>
                </div>
            </Fade>
        );
    }

    private renderProjectsOnMobile(project: any, index: number) {
        return(
            <Fade spy={this.animKey} right>
                <div className={this.getProjectTheme(Themes[index])}>
                    <Text className={styles.projectName}>{project.name}</Text><br/>
                    <Fade spy={this.animKey} bottom>
                        <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.projectImage} image={this.getProjectImage(project.name) as ImageNames}/><br/>
                    </Fade>
                    <Text className={styles.projectDescription}>{project.summary}</Text><br/>
                </div>
            </Fade>
        );
    }

    private loadComponent () {
        if (this.loaderStore.areResourcesLoaded && this.commentSectionStore.areCommentsDisplayed) {
            this.loaderStore.componentLoaded = true;
            return false;
        }
        return true;
    }
}