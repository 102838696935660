import { makeObservable, observable, action, computed } from 'mobx';
export class CommentSectionStore {

    @observable public areCommentsDisplayed: boolean = false;

    constructor() {
        this.areCommentsDisplayed = false;
        makeObservable(this);
    }

    @action
    public updateCommentDisplayStatus = () => {
        this.areCommentsDisplayed = true;
    }

    @computed
    public get getCommentDisplayStatus() {
        return this.areCommentsDisplayed;
    }
}