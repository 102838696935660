import * as React from 'react';
import {Menu} from 'antd';
import  styles from './NavMenu.module.scss';
import { MenuIcon } from './MenuIcon';
import { IconLibrary } from '../Shared/IconLibrary';
import { inject, observer} from 'mobx-react';
import { TabStore } from '../../stores/TabStore';
import autoBind from 'auto-bind';
import sideBarStore from '../../stores/SideBarStore';
import navDrawerStore from '../../stores/NavDrawerStore';

const { SubMenu, Item } = Menu;

@inject("tabStore")
@observer
export class NavMenu extends React.Component < {
  tabStore : TabStore
} > {
  
  constructor(props : any) {
    super(props);
    autoBind(this);
  }

  render() {
    var menuList = ['Education', 'Experience', 'Skills', 'Projects', 'Interests', 'Connect']; 
    const {tabStore} = this.props;
    
    return (
        <Menu
            mode="inline"
            defaultOpenKeys={['subMenu']}
            className={styles.navMenu}
            >
            <SubMenu onTitleClick={() => sideBarStore.updateSideBarMenuCollapseStatus()} className={styles.navSubMenu} key="subMenu" icon={this.getFolderIconType()} title={<>&nbsp;&nbsp;My Portfolio</>}>
                {
                  menuList.map((element, index) => {
                      var selecteTab: string = element as string;
                      return  (
                          <Item
                          key={index}
                          onClick={() => { navDrawerStore.updateDrawerVisibility(); tabStore.addActiveTab(selecteTab);}} 
                          icon={<MenuIcon type={element}/>}>
                              &nbsp;&nbsp;{`${element}.me`}
                          </Item>
                      )  
                  })
                }
            </SubMenu>
        </Menu>
    )
  }

  getFolderIconType() {
    if (sideBarStore.getSideBarMenuCollapseStatus) {
      return  <IconLibrary icon="folder" color="#ffca18" />;   
    }
    return <IconLibrary icon="folder-open" color="#ffca18" />;
  }
}