import { makeObservable, observable, action, computed } from 'mobx';

export class ComponentLoaderStore {

    private resourcesCount: number = 0;
    @observable public loadedResourcesCount: number = 0;
    @observable public componentLoaded: boolean = false;

    constructor() { 
        makeObservable(this);
    }

    @action
    public setNumberOfResourcesToLoad = (count: number) => {
        this.resourcesCount = count;
    }

    @action
    public updateLoadedResourceCount = () => {
        this.loadedResourcesCount = this.loadedResourcesCount + 1;
        if (this.loadedResourcesCount === this.resourcesCount && this.loadedResourcesCount !== 0) {
            this.componentLoaded = true;
        }
    }
    
    @computed
    public get areResourcesLoaded() {
        return this.loadedResourcesCount === this.resourcesCount && this.loadedResourcesCount !== 0 ;
    }
}