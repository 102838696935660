import { Timeline } from 'antd';
import * as React from 'react';
import  styles from './EducationSection.module.scss';
import Fade from 'react-reveal/Fade';
import { IconLibrary } from '../../Shared/IconLibrary';
import { CollegeCard, DegreeType } from './CollegeCard';
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { observer, Provider } from 'mobx-react';
import { ImageLibrary } from '../../Shared/ImageLibrary';
import { SectionHeader } from '../SectionHeader';
import resumeJSON from '../../../data/resume.json';
import { isMobile } from "react-device-detect";
import { ComponentLoaderStore } from '../../../stores/ComponentLoaderStore';
import { CommentSection } from '../../Shared/CommentSection';
import { CommentSectionStore } from '../../../stores/CommentSectionStore';

@observer
export class EducationSection extends React.Component {

    animKey = Math.random();
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    loaderStore: ComponentLoaderStore = new ComponentLoaderStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();

    componentDidMount() {
        this.loaderStore.setNumberOfResourcesToLoad(8);
        this.codeLineNumberStore.updateSectionHeight(document.getElementById("edu")?.clientHeight as number);
    }

    public render() {
        this.animKey = Math.random();
        return (
            <div className={styles.educationSectionWrapper} onLoad={() => this.codeLineNumberStore.updateSectionHeight(document.getElementById("edu")?.clientHeight as number)}>
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="My educational background till date." store={this.commentSectionStore}/><br/>
                </Provider>
                <div className={this.loadComponent() ? styles.hidden : styles.educationSection} id="edu">
                    <Fade spy={this.animKey} duration={500} top>
                        <SectionHeader>Education</SectionHeader>
                    </Fade>
                    <Timeline mode={isMobile ? undefined : "alternate"}>
                        {this.renderTimeline().map((timeline) => { return timeline; })}
                    </Timeline>
                </div>
            </div>
        );
    }

    private renderBachelorsIllustration(): JSX.Element {
        return (
            <div className={styles.bachelorsIllustration}>
                <div className={styles.onTableItems}>
                <Fade spy={this.animKey} duration={1000} left>
                    <div className={styles.coffeemug}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="coffeePng"/></div>
                </Fade>
                <Fade spy={this.animKey} duration={2000}>
                    <div className={styles.laptop}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="laptopPng"/></div>
                </Fade>
                <Fade spy={this.animKey} duration={1000} top>
                    <div className={styles.books}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="booksPng"/></div>
                </Fade>
                </div>
                <div><Fade spy={this.animKey} duration={500} bottom><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="tablePng"/></Fade></div>
            </div>
        );
    }

    private getIllustration(degree: string) {
        switch(degree) {
            case "Graduation":
                return this.renderBachelorsIllustration();
            case "Post Graduation":
                return this.renderMastersIllustration();
        }
    }

    private getCardStyle(degree: string) {
        switch(degree) {
            case "Graduation":
                return styles.bachelorsDegreeCard;
            case "Post Graduation":
                return styles.masterDegreeCard;
        }
    }

    private renderMastersIllustration(): JSX.Element {
        return(
            <div className={styles.mastersIllustration}>
                <div className={styles.onTableItems}>
                <Fade spy={this.animKey} duration={1000} left>
                    <div className={styles.lamp}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="lampPng"/></div>
                </Fade>
                <Fade spy={this.animKey} duration={1000}>
                    <div className={styles.monitor}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="monitorPng"/></div>
                </Fade>
                <Fade spy={this.animKey} duration={1000} top>
                    <div className={styles.coffeeCup}><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="coffeeCupPng"/></div>
                </Fade>
                </div>
                <div><Fade spy={this.animKey} duration={500} bottom><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} image="officeTablePng"/></Fade></div>
            </div>
        );
    }

    private getDegreeType(degree: string) {
        switch(degree) {
            case "Graduation":
                return DegreeType.BE
            case "Post Graduation":
                return DegreeType.MS
        }  
    }

    private renderTimeline () {
        var timeline: JSX.Element [] = [];
        resumeJSON.education.forEach(course => {
            if (isMobile) {
                timeline.push(
                    <Timeline.Item color="green" dot={<IconLibrary color="#feb029" icon="university"/>}>
                      {`${course.studyType} begins in ${course.startDate}`}
                    </Timeline.Item>          
                );
                timeline.push(
                    <Timeline.Item>
                        <div className={this.getCardStyle(course.studyType)}>
                            <Fade spy={this.animKey} duration={500} right>
                                <CollegeCard
                                    collegeName = {course.institution}
                                    collegeDegree = {course.area}
                                    collegeGPA = {course.gpa}
                                    degreeType = {this.getDegreeType(course.studyType) as DegreeType}
                                />
                            </Fade>
                        </div>
                    </Timeline.Item>
                );
                timeline.push(
                    <Timeline.Item>
                        {this.getIllustration(course.studyType)}
                    </Timeline.Item>
                );
                timeline.push(
                    <Timeline.Item dot={<IconLibrary color="#feb029" icon="graduation-cap"/>}>
                        {course.studyType} completed in {course.endDate}
                    </Timeline.Item>
                );
            } else {
                timeline.push(
                    <Timeline.Item color="green" label={course.startDate} dot={<IconLibrary color="#feb029" icon="university"/>}>
                      {`${course.studyType} begins`}
                    </Timeline.Item>          
                );
                timeline.push(
                    <Timeline.Item label={this.getIllustration(course.studyType)}>
                        <div className={this.getCardStyle(course.studyType)}>
                            <Fade spy={this.animKey} duration={500} left>
                                <CollegeCard
                                    collegeName = {course.institution}
                                    collegeDegree = {course.area}
                                    collegeGPA = {course.gpa}
                                    degreeType = {this.getDegreeType(course.studyType) as DegreeType}
                                />
                            </Fade>
                        </div>
                    </Timeline.Item>
                );
                timeline.push(
                    <Timeline.Item dot={<IconLibrary color="#feb029" icon="graduation-cap"/>} label={course.endDate}>
                        {course.studyType} completed
                    </Timeline.Item>
                );
            }
        });
        return timeline;
    }

    private loadComponent () {
        if (this.loaderStore.areResourcesLoaded && this.commentSectionStore.areCommentsDisplayed) {
            this.loaderStore.componentLoaded = true;
            return false;
        }
        return true;
    }
}