import * as React from 'react';
import styles from './MainContent.module.scss';
import {Layout} from 'antd';

export class MainContent extends React.Component {
    public render() {
        return (
            <Layout.Content className={styles.mainContent}>
                <Layout className={styles.mainContentLayout}>
                    {this.props.children}
                </Layout>
            </Layout.Content>
        )
    }
}