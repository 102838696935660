import * as React from 'react';
import  styles from './CollegeCard.module.scss';
import { Card, Typography } from 'antd';

const {Text} = Typography;

export enum DegreeType {
    BE,
    MS
}

interface CollegeCardProps {
    collegeName: string;
    collegeDegree: string;
    collegeGPA: string;
    degreeType: DegreeType
}

export class CollegeCard extends React.Component<CollegeCardProps> {

    render() {
        var degreeStyle = undefined;
        
        switch(this.props.degreeType) {
            case DegreeType.BE:
                degreeStyle = styles.collegeCardBE;
                break;
            case DegreeType.MS:
                degreeStyle = styles.collegeCardMS;
                break;
        }

        return (
            <Card className={degreeStyle}>
                <Text className={styles.collegeName}>{this.props.collegeName}</Text><br/><hr/>
                <Text className={styles.collegeDegree}>{this.props.collegeDegree}</Text><br/><hr/>
                <Text className={styles.collegeGPA}>{this.props.collegeGPA}</Text>
            </Card>
        );
    }
}