import React from 'react';
import  styles from './App.module.scss';
import {Layout} from 'antd';
import 'antd/dist/antd.css';
import 'react-typist/dist/Typist.css';
import { Header } from './Components/Header/Header';
import { MainContent } from './Components/MainContent/MainContent';
import { SideBar } from './Components/SideBar/SideBar';
import { Footer } from './Components/Footer/Footer';
import { ResumeSections } from './Components/ResumeSections/ResumeSections';
import { TabStore } from './stores/TabStore';
import { Provider } from 'mobx-react';
import { isMobile } from "react-device-detect";
import { NavDrawer } from './Components/NavDrawer/NavDrawer';

function App() {
  const tabStore: TabStore = new TabStore();
  return (
    <div className={styles.App}>
      <Layout className={styles.App}>
        <Header/>
        <MainContent>
          <Provider tabStore={tabStore}>
            {isMobile ? (
              <NavDrawer tabStore={tabStore}/>
            ) : (
              <SideBar tabStore={tabStore} />
            )}
            <ResumeSections tabStore={tabStore} />
          </Provider>
        </MainContent>
        <Footer/>
      </Layout>
    </div>
  );
}

export default App;
