import { makeObservable, observable, action, computed } from 'mobx';

export class TabStore {

    @observable public activeTab: string = "";
    @observable public tabs: string[] = [];

    constructor() { 
        makeObservable(this);
    }

    @action
    public addActiveTab = (tabName: string) => {
        if (!this.tabs.find(tab => tab === tabName)) {
            this.tabs.push(tabName);
        }
        this.changeTab(tabName);
    }

    @action
    public changeTab = (tabName: string) => {
        this.activeTab = tabName;
    }

    @action 
    public removeTab = (tabName: string) => {
        let newActiveTab = this.activeTab;
        let lastIndex = 0;
        this.tabs.forEach((tab, i) => {
            if (tab === tabName) {
                lastIndex = i - 1;
            }
        });
        this.tabs = this.tabs.filter((tab) => tab !== tabName );
        if (this.tabs.length && newActiveTab === tabName) {
            if (lastIndex >= 0) {
                newActiveTab = this.tabs[lastIndex];
            } else {
                newActiveTab = this.tabs[0];
            }
        }
        this.activeTab = newActiveTab;
    }

    @computed
    public get isDefaultState() {
        return this.tabs.length === 0;
    }

}