import { Image } from 'antd';
import * as React from 'react';

import azuredatafactoryPNG from '../../resources/azuredatafactory.png';
import azurefunctionPNG from '../../resources/azurefunction.png';
import cosmosPNG from '../../resources/cosmos.png';
import logicappPNG from '../../resources/logicapp.png';
import xamarinPNG from '../../resources/xamarin.png';
import typescriptPNG from '../../resources/typescript.png';
import azurePNG from '../../resources/azure.png';
import booksPng from '../../resources/books.png';
import coffeePng from '../../resources/coffee.png';
import laptopPng from '../../resources/laptop.png';
import tablePng from '../../resources/table.png';
import monitorPng from '../../resources/monitor.png';
import coffeeCupPng from '../../resources/coffeeCup.png';
import lampPng from '../../resources/lamp.png';
import officeTablePng from '../../resources/officeTable.png';
import indianPng from '../../resources/indian.png';
import einstienPng from '../../resources/einstien.png';
import batmanPng from '../../resources/batman.png';
import randomPng from '../../resources/random.png';
import heisenbergPng from '../../resources/heisenberg.png';
import connectImagePng from '../../resources/connectimage.jpg';
import netflixGIF from '../../resources/netflix.gif';
import foodGIF from '../../resources/food.gif';
import gamingGIF from '../../resources/gaming.gif';
import realmadridGIF from '../../resources/realmadrid.gif';
import travelGIF from '../../resources/travel.gif';
import uselectoralGIF from '../../resources/uselectoral.gif';
import codingcalendarPNG from '../../resources/codingcalendar.png';
import pextripJPG from '../../resources/pextrip.jpg';
import userPng from '../../resources/nikit.jpg';
import indiaFlagPng from '../../resources/indiaflag.png';
import nikitWelcomeImage from '../../resources/nikitWelcomeImage.jpg'

export type ImageNames = 
    'azuredatafactoryPNG' |
    'azurefunctionPNG' |
    'cosmosPNG' |
    'logicappPNG' |
    'xamarinPNG' |
    'typescriptPNG' |
    'azurePNG' |
    'booksPng' |
    'coffeePng' |
    'laptopPng' |
    'tablePng' |
    'monitorPng' |
    'coffeeCupPng' |
    'lampPng' |
    'officeTablePng' |
    'indianPng' |
    'einstienPng' |
    'batmanPng' |
    'randomPng' |
    'heisenbergPng' |
    'connectImagePng' |
    'netflixGIF' |
    'foodGIF' |
    'gamingGIF' |
    'realmadridGIF' |
    'travelGIF' |
    'uselectoralGIF' |
    'codingcalendarPNG' |
    'pextripJPG' |
    'userPng' |
    'indiaFlagPng' |
    'nikitWelcomeImage';

const ImageLookUp = {
    'azuredatafactoryPNG': azuredatafactoryPNG,
    'azurefunctionPNG': azurefunctionPNG,
    'cosmosPNG': cosmosPNG,
    'logicappPNG': logicappPNG,
    'typescriptPNG': typescriptPNG,
    'xamarinPNG': xamarinPNG,
    'azurePNG': azurePNG,
    'booksPng': booksPng,
    'coffeePng': coffeePng,
    'laptopPng': laptopPng,
    'tablePng': tablePng,
    'monitorPng': monitorPng,
    'coffeeCupPng': coffeeCupPng,
    'lampPng': lampPng,
    'officeTablePng': officeTablePng,
    'indianPng': indianPng,
    'einstienPng': einstienPng,
    'batmanPng': batmanPng,
    'randomPng': randomPng,
    'heisenbergPng': heisenbergPng,
    'connectImagePng': connectImagePng,
    'netflixGIF': netflixGIF,
    'foodGIF': foodGIF,
    'gamingGIF': gamingGIF,
    'realmadridGIF': realmadridGIF,
    'travelGIF': travelGIF,
    'uselectoralGIF': uselectoralGIF,
    'codingcalendarPNG': codingcalendarPNG,
    'pextripJPG': pextripJPG,
    'userPng': userPng,
    'indiaFlagPng': indiaFlagPng,
    'nikitWelcomeImage': nikitWelcomeImage,
}

export interface ImageProps {
    image: ImageNames;
}

export class ImageLibrary extends React.Component<ImageProps & React.HTMLAttributes<HTMLDivElement>, {}> {

    render() {
        return(
            <Image onLoad={this.props.onLoad} className={this.props.className} preview={false} src={ImageLookUp[this.props.image]}/>
        );
    }
}