import * as React from 'react';
import { IconLibrary } from './IconLibrary';

interface MenuIconProps {
    type: string;
}

export class MenuIcon extends React.Component<MenuIconProps> {
    
    public render() {
        var icon: JSX.Element = <IconLibrary color="#e76a72" icon="graduation-cap"/>;
        switch(this.props.type) {
            case "Education":
                icon = <IconLibrary color="#e75c62" icon="graduation-cap"/>
                break;
            case "Experience":
                icon = <IconLibrary color="#36a2eb" icon="briefcase"/>
                break;
            case "Skills":
                icon = <IconLibrary color="#8e4c6f" icon="laptop-code"/>
                break;
            case "Projects":
                icon = <IconLibrary color="#a7d129" icon="rocket"/>
                break;
            case "Interests":
                icon = <IconLibrary color="#feb029" icon="gamepad"/>
                break;
            case "Connect":
                icon = <IconLibrary color="#67d1e0" icon="users"/>
                break;
                
        };
        return icon
    }
}