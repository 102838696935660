import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { library, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Tooltip} from 'antd';
import { faFolderOpen, faHeart, faCoffee, faCodeBranch, faFolder, faFile, faGraduationCap, faBriefcase, faLaptopCode, faGamepad, faRocket, faUsers, faCircle, faGrimace, faUniversity, faSync, faBars } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faMicrosoft, faJava, faReact, faNodeJs, faAndroid, faDocker } from "@fortawesome/free-brands-svg-icons";
import {faHandSpock, faGrinBeam} from '@fortawesome/free-regular-svg-icons'

library.add(
    faCodeBranch, 
    faCoffee, 
    faHeart,
    faLinkedin,
    faBriefcase,
    faCodeBranch,
    faGraduationCap,
    faFolder,
    faFile,
    faLaptopCode,
    faGamepad,
    faRocket,
    faUsers,
    faCircle,
    faHandSpock,
    faGrinBeam,
    faGrimace,
    faUniversity,
    faSync,
    faMicrosoft,
    faJava,
    faReact,
    faNodeJs,
    faAndroid,
    faDocker,
    faFolderOpen,
    faBars
);

interface IconProps {
    color: string;
    icon: IconProp;
    tooltip?: JSX.Element;
}

export class IconLibrary extends React.Component<IconProps> {
    
    public render(){
        var icon: JSX.Element;
        if (this.props.tooltip){
            icon = <Tooltip title={this.props.tooltip}><FontAwesomeIcon size={'lg'} icon={this.props.icon}  color={this.props.color}/></Tooltip>
        } else {
            icon = <FontAwesomeIcon icon={this.props.icon as IconProp}  color={this.props.color}/>
        }
        return icon;
    }
}