import autoBind from 'auto-bind';
import { inject, observer } from 'mobx-react';
import * as React from 'react';
import { CodeLineNumberStore } from '../../stores/CodeLineNumberStore';
import  styles from './CodeLineNumbers.module.scss';

@inject("store")
@observer
export class CodeLineNumbers extends React.Component < { 
    store : CodeLineNumberStore
  } > {

    constructor(props : any) {
        super(props);
        autoBind(this);
    }

    render() {
        
        const {store} = this.props;
        var height = store.sectionHeight;
        var lineNumbers: string = ""; 
        
        for (var i = 1; i < height/15 - 2; i++ ) {
            lineNumbers = lineNumbers.concat(i.toString(), " ");
        }

        return (
            <div className={styles.lineNumbers} style={{ height: `${height}px`}}>
                {lineNumbers}
            </div>
        );
    }
}