import { makeObservable, observable, action, computed } from 'mobx';
class NotificationStore {

    @observable public notificationStatus: boolean = false;

    constructor() { 
        makeObservable(this);
    }

    @action
    public updateNotificationStatus = () => {
        this.notificationStatus = true;
    }

    @computed
    public get getNotificationStatus() {
        return this.notificationStatus;
    }
}
const notifcationStore = new NotificationStore()

export default notifcationStore