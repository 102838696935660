import { Button, Tooltip } from 'antd';
import * as React from 'react';
import  styles from './ConnectSection.module.scss';
import Text from 'antd/lib/typography/Text';
import { IconLibrary } from '../../Shared/IconLibrary';
import emoji from 'node-emoji'
import Fade from 'react-reveal/Fade';
import { CodeLineNumbers } from '../CodeLineNumbers';
import { CodeLineNumberStore } from '../../../stores/CodeLineNumberStore';
import { observer, Provider } from 'mobx-react';
import { ImageLibrary } from '../../Shared/ImageLibrary';
import { SectionHeader } from '../SectionHeader';
import resumeJSON from '../../../data/resume.json';
import { isMobile } from "react-device-detect";
import { ComponentLoaderStore } from '../../../stores/ComponentLoaderStore';
import { CommentSection } from '../../Shared/CommentSection';
import { CommentSectionStore } from '../../../stores/CommentSectionStore';
@observer
export class ConnectSection extends React.Component {
    animKey = Math.random();
    codeLineNumberStore: CodeLineNumberStore = new CodeLineNumberStore();
    loaderStore: ComponentLoaderStore = new ComponentLoaderStore();
    commentSectionStore: CommentSectionStore = new CommentSectionStore();
    
    componentDidMount() {
        this.commentSectionStore = new CommentSectionStore();
        this.loaderStore.setNumberOfResourcesToLoad(6);
        this.codeLineNumberStore.updateSectionHeight(document.getElementById("connect")?.clientHeight as number);
    }

    render() {
        this.animKey = Math.random();
        this.codeLineNumberStore.updateSectionHeight(document.getElementById("connect")?.clientHeight as number);
        return (
            <div className={styles.connectSection} id="connect">
                <Provider store={this.codeLineNumberStore}>
                    <CodeLineNumbers store={this.codeLineNumberStore}/>
                    <CommentSection comment="Let's connect, shall we ?" store={this.commentSectionStore}/>
                </Provider>
                {isMobile ? (
                    this.getConnectSectionContentForMobile()
                ) : (
                    this.getConnectSectionContent()
                )}
            </div>
        );
    }

    private getLinkedInURL() {
        return resumeJSON.basics.profiles.filter((profile) => profile.network === "LinkedIn")[0].url;
    }

    private getConnectSectionContent() {
        return (
            <div className={this.loadComponent() ? styles.hidden : styles.connectionContentWrapper}>
                <div className={styles.connectdpWrapper}>
                    <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.connectdp} image="connectImagePng"/>
                </div>
                <div  className={styles.connectTextWrapper}>
                    <SectionHeader>Let's Connect !</SectionHeader>
                    <br/>
                    <Fade spy={this.animKey} duration={500} top>
                        <Tooltip title="I am the one who knocks !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="heisenbergPng"/></div></Tooltip>
                    </Fade>
                    <Fade spy={this.animKey} duration={1000} top>
                        <Tooltip title="I am vengeance !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="batmanPng"/></div></Tooltip>
                    </Fade>
                    <Fade spy={this.animKey} duration={1500} top>
                        <Tooltip title={<div>E = mc<sup>2</sup> !!!</div>} color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="einstienPng"/></div></Tooltip>
                    </Fade>
                    <Fade spy={this.animKey} duration={2000} top>
                        <Tooltip title="Hello World !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="randomPng"/></div></Tooltip>
                    </Fade>
                    <Fade spy={this.animKey} duration={2500} top>
                        <Tooltip title="Namaste !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="indianPng"/></div></Tooltip>
                    </Fade>
                    <br/>
                    <Text className={styles.connectText}>Hey there !!! {emoji.emojify(':wave:')} Thanks for visiting my profile. Let's connect and get to know each other. Let's learn from each other. Let's collaborate and innovate together. {emoji.emojify(':grin:')}</Text>
                    <br/>
                    <Button className={styles.connectButton} href={this.getLinkedInURL()} target="blank"><IconLibrary color="white" icon={['fab', 'linkedin']}/>&nbsp;Connect</Button>&nbsp;&nbsp;
                    <Button className={styles.resumeButton} href="/resume.pdf" target='_blank' rel='noopener noreferrer'>&nbsp;My Resume</Button>
                    <div className={styles.attribution} >Avatar Icons made by <a href="https://www.iconfinder.com/laurareen" title="Laura Reen">Laura Reen</a> from <a href="https://www.iconfinder.com/" title="iconfinder">www.iconfinder.com</a>. Licensed under CC BY-NC 3.0.</div>
                </div>
            </div>
        );
    }

    private getConnectSectionContentForMobile() {
        return (
            <div className={this.loadComponent() ? styles.hidden : styles.connectionContentWrapper}>
                <SectionHeader>Let's Connect !</SectionHeader>
                <br/>
                <Fade spy={this.animKey}duration={500} top>
                    <Tooltip title="I am the one who knocks !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="heisenbergPng"/></div></Tooltip>
                </Fade>
                <Fade spy={this.animKey}duration={1000} top>
                    <Tooltip title="I am vengeance !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="batmanPng"/></div></Tooltip>
                </Fade>
                <Fade spy={this.animKey}duration={1500} top>
                    <Tooltip title={<div>E = mc<sup>2</sup> !!!</div>} color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="einstienPng"/></div></Tooltip>
                </Fade>
                <Fade spy={this.animKey} duration={2000} top>
                    <Tooltip title="Hello World !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="randomPng"/></div></Tooltip>
                </Fade>
                <Fade spy={this.animKey} duration={2500} top>
                    <Tooltip title="Namaste !!!" color="cyan"><div><ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.friendAvatar} image="indianPng"/></div></Tooltip>
                </Fade>
                <br/>
                <Text className={styles.connectText}>Hey there !!! {emoji.emojify(':wave:')} Thanks for visiting my profile. Let's connect and get to know each other. Let's learn from each other. Let's collaborate and innovate together. {emoji.emojify(':grin:')}</Text>
                <br/>
                <ImageLibrary onLoad={() => this.loaderStore.updateLoadedResourceCount()} className={styles.connectdp} image="connectImagePng"/>
                <br/>
                <Button className={styles.connectButton} href={this.getLinkedInURL()} target="blank"><IconLibrary color="white" icon={['fab', 'linkedin']}/>&nbsp;Connect</Button>&nbsp;&nbsp;
                <Button className={styles.resumeButton} href="/resume.pdf" target='_blank' rel='noopener noreferrer'>&nbsp;My Resume</Button>
                <div className={styles.attribution} >Avatar Icons made by <a href="https://www.iconfinder.com/laurareen" title="Laura Reen">Laura Reen</a> from <a href="https://www.iconfinder.com/" title="iconfinder">www.iconfinder.com</a>. Licensed under CC BY-NC 3.0.</div>
            </div>
        );
    }

    private loadComponent () {
        if (this.loaderStore.areResourcesLoaded && this.commentSectionStore.areCommentsDisplayed) {
            this.loaderStore.componentLoaded = true;
            return false;
        }
        return true;
    }
}